@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import "mapbox-gl/dist/mapbox-gl.css";
@import "./embla.css";
@import "yet-another-react-lightbox/styles.css";
@import "yet-another-react-lightbox/plugins/thumbnails.css";

@layer components {
	.content-container {
		@apply md:max-w-[1400px] w-full mx-auto;
	}
}

html,
body {
	padding: 0;
	margin: 0;
	@apply font-body;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@apply font-heading mb-2;
}

h1 {
	@apply text-5xl leading-10;
}
h2 {
	@apply text-4xl leading-9;
}
h3 {
	@apply text-3xl leading-8;
}
h4 {
	@apply text-2xl leading-7;
}

:root {
	@apply antialiased;
	--swiper-navigation-color: var(--text-white);
}

.mapboxgl-popup-content {
	@apply text-primary p-4;
}

.mapboxgl-popup-close-button {
	@apply p-2;
}

.bg-primary-base {
	background: oklch(var(--p));
}
